<template>
    <div>
        <img class="example-headImg" src="../../assets/image/technicalService/04-3-5banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">快速交付平台</div>
            <div class="head-explain-content">旨在帮助大型传统企业克服数字化转型过程中面临的各种挑战，</div>
            <div class="head-explain-content" style="margin-top:-3rem">可以简单，快速，高效地开发出企业级的应用系统。</div>
        </div>
        <div class="custom-head-list">
            <div class="box">
                <div class="custom-list-item custom-list-ischeck"><span class="title">总览</span></div>
                <div class="custom-list-item"><span class="title">特点</span></div>
                <div class="custom-list-item"><span class="title">优势</span></div>
            </div>
        </div>
        <div class="container-1">
            <div class="custom-box1" style="margin-bottom:5.8rem">
                <div class="title1">总览</div>
                <div class="title2">通过快速交付平台，企业业务部门可直接通过配置实现包含表单、流程、报表等业务功能。此外，我们提供了包括组织架构、
                    权限体系、认证授权等系统功能和流媒体、IM、在线文档等高级功能，使得企业可以免去企业应用基础功能的开发，而专注实现业务功能。<br/>
                    针对业务功能的开发，我们产品还提供了需求、开发、测试规范，并提供基于开发规范的IDE检查插件及代码扫描插件。<br/>
                    除了对企业信息系统功能性需求的支持，我们产品还为企业提供了基于云计算的监控预警、日志分析、高可用、系统容灾、本地系统上云等企业
                    级解决方案以保证系统合规、可靠、高效。</div>
                <img class="img1" src="../../assets/image/technicalService/04-3-5总览.png"/>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 49rem;">
            <div class="custom-box1" style="margin-top:0rem;">
                <div class="title1">特点</div>
                <div>
                    <div class="box2-1" style="margin-top:2.4rem">
                        <div class="big-card">
                            <div class="card">
                                <img src="../../assets/image/technicalService/04-3-5见即所得.svg"/>
                                <div class="title2-1">见即所得</div>
                                <div class="title2-2">丰富的表单组件 ,  帮助企业用户以简单拖拽即可生成自定义表单页面 ,  轻松实现业务数据定义。</div>
                            </div>
                        </div>
                        <div class="big-card">
                            <div class="card">
                                <img src="../../assets/image/technicalService/04-3-1蓝图设计.svg"/>
                                <div class="title2-1">流程定制便捷</div>
                                <div class="title2-2">通过封闭式3天集中沟通，头脑风暴，形成图文并茂的需求文档。</div>
                            </div>
                        </div>
                        <div class="big-card">
                            <div class="card">
                                <img src="../../assets/image/technicalService/04-3-1系统演示.svg"/>
                                <div class="title2-1">业务事件清晰全面</div>
                                <div class="title2-2">通过封闭式3天集中沟通，头脑风暴，形成图文并茂的需求文档。</div>
                            </div>
                        </div>
                        <div class="big-card">
                            <div class="card">
                                <img src="../../assets/image/technicalService/04-3-1功能确认.svg"/>
                                <div class="title2-1">权限配置完善</div>
                                <div class="title2-2">通过封闭式3天集中沟通，头脑风暴，形成图文并茂的需求文档。</div>
                            </div>
                        </div>
                        <div class="big-card">
                            <div class="card">
                                <img src="../../assets/image/technicalService/04-3-1功能确认.svg"/>
                                <div class="title2-1">二次开发灵活</div>
                                <div class="title2-2">通过封闭式3天集中沟通，头脑风暴，形成图文并茂的需求文档。</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-1">
            <div class="custom-box1" style="margin-top:0rem;">
                <div class="title1">优势</div>
                <div>
                    <div class="box2-2" style="margin-top:2.4rem">
                        <div class="card" style="background-color:#F3FAF6;">
                            <img src="../../assets/image/technicalService/04-3-5统一业务.svg" style="width:5rem;height:5rem"/>
                            <div class="content">
                                <div class="title1">统一业务认知</div>
                                <div class="title2">基于可视化应用程序开发环境 ,  借助表单工具、流程设计和报表设计等模块 , 业务人员和技术人员能快速达成业务统   一理解 ,
                                    在降低沟通成本的同时能快速完成从业务构想到系统落地的完整闭环。</div>
                            </div>

                        </div>
                        <div class="card" style="background-color:#F9F9F9;">
                            <img src="../../assets/image/technicalService/04-3-5多样化.svg"/>
                            <div class="content">
                                <div class="title1">满足多样化需求</div>
                                <div class="title2">平台通过组件搭建的模式构建系统 ,  可以满足企业不同阶段的数字化需求 ,  契合企业当下的业务探索的诉求 ，
                                    保障企业的长期竞争力。</div>
                            </div>

                        </div>
                        <div class="card" style="background-color:#F9F9F9;margin-top: 1rem;">
                            <img src="../../assets/image/technicalService/04-3-5低成本.svg"/>
                            <div class="content">
                                <div class="title1">低成本和高效率</div>
                                <div class="title2">和传统开发模式相比 ,  梦天门快速交付平台极大程度的降低了业务系统搭建的人工成本 ,  缩短了开发周期。没有技
                                    术背景的用户也能通过平台提供的可视化 的应用搭建系统完成简单业务系统的搭建。</div>
                            </div>

                        </div>
                        <div class="card" style="background-color:#F3FAF6;margin-top: 1rem;">
                            <img src="../../assets/image/technicalService/04-3-5挖掘数据.svg"/>
                            <div class="content">
                                <div class="title1">挖掘数据资产</div>
                                <div class="title2">快速交付平台信息存储模型具有高度一致性 ,  标准化接口完成企业内多套业务系统数据集成,打破企业内信息孤岛
                                    。在统一的数据存储基础上,  管理人员可以快速从企业各类业务数据完成关联关系建立,更好地挖掘企业本身业务潜力。</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {
            checkIndex:1,
            activeIndex:'4'
        };
    },

    mounted() {

    },

    methods: {
        jumpRout(route,index){
            this.activeIndex = index
            localStorage.setItem('headId',index);
            this.$router.push(route)
        }
    },
};
</script>

<style lang="less" scoped>
.custom-head-list{
    width: 190rem;
    border-bottom: 2px solid #F8F8F8;
    height: 7.9rem;
    display: flex;
    .box{
        width: 139rem;
        margin: auto;
        display: flex;
    }
}
.custom-list-item{
    width: 20rem;
    height: 8rem;
    display: flex;
    text-align: center;
    align-items: center;
    .title{
        width: 100%;
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #4F4F4F;
        line-height: 20px;
        text-align: center;
    }
}
.custom-list-ischeck{
    border-bottom: 2px solid #11A84F;
    color: #11A84F;
}
.custon-title1{
    width: 139rem;
    text-align: left;
    margin:6rem 0 1.7rem  26.1rem;
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.custom-img1{
    width: 139.5rem;
    height: 52rem;
    margin: auto;
    margin-top: 6rem;
}
.custom-box1{
    margin-top:6rem;
    width: 139rem;
    margin: auto;
    .title1{
        // height: 2rem;
        font-size: 2rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #000000;
        line-height: 3rem;
        text-align: left;
        margin-top: 6rem;
    }
    .title2{
        width: 139.5rem;
        height: 16rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
        text-align: left;
        margin-top: 3.1rem;
    }
    .img1{
        width: 139.5rem;
        height: 52.9rem;
        margin-top: 4rem;
    }
    .box2-1{
        width: 139rem;
        height: 31.9rem;
        margin-bottom: 1rem;
        /*设置当前元素为flex模式*/
        display: flex;
        /*行元素默认不折行，设置为折行*/
        flex-wrap: wrap;
        justify-content: space-between;
        .big-card{
            .card{
                width: 27rem;
                height: 21rem;
                background: #FFFFFF;
                box-shadow: 0px 0px 12px 0px rgba(233,233,233,0.6);
                overflow: hidden;
                img{
                    // margin: auto;
                    margin-top: 4rem;
                }
                .title2-1{
                    height: 2rem;
                    font-size: 2rem;
                    font-family: 'CN_Medium';
                    font-weight: 500;
                    color: #000000;
                    line-height: 3rem;

                    margin: 3rem 0 0 0;
                }
                .title2-2{
                    width: 21rem;
                    height: 0rem;
                    font-size: 1.6rem;
                    font-family: 'CN_Regular';
                    font-weight: 400;
                    color: #4F4F4F;
                    line-height: 3rem;
                    overflow: hidden;

                    margin: auto;
                    margin-top: 1.5rem;
                    text-align: left;
                }
            }
        }

        .big-card:hover>.card{
            height: 31.9rem;
            transform: rotateY(360deg);
            transition: all 1.5s;
        }
        .big-card:hover>.card .title2-2{
            height:14.5rem;
            transition: all 0s;
        }

    }
    .box2-2{
        width: 139rem;
        margin-bottom: 1rem;
        /*设置当前元素为flex模式*/
        display: flex;
        /*行元素默认不折行，设置为折行*/
        flex-wrap: wrap;
        justify-content: space-between;
        .card{
            width: 69.2rem;
            height: 21rem;
            display: flex;
            img{
                // margin: auto;
                width: 4rem;
                height: 4rem;
                margin: 3rem 0 0 2rem;
            }
            .content{
                margin: 3rem 0 0 3.3rem;
                .title1{
                    height: 2rem;
                    font-size: 2rem;
                    font-family: 'CN_Medium';
                    font-weight: 500;
                    color: #000000;
                    line-height: 3rem;
                    margin: 0;
                }
                .title2{
                    width: 56.1rem;
                    font-size: 16px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #4F4F4F;
                    line-height: 3rem;
                    margin: 1rem 0 0 0;
                }
            }
        }

    }
    .custom-more{
        width: 14rem;
        height: 4.6rem;
        border: 1px solid #11A84F;
        margin: auto;
        display: flex;
        text-align: center;
        align-items: center;
        cursor: pointer;

        margin-top: 4.5rem;
        span{
            width: 6.4rem;
            height: 3rem;
            font-size: 1.6rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #11A84F;
            line-height: 3rem;
            margin: auto;
        }
    }
}
</style>
